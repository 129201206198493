import React from "react";
import styled from "styled-components";
import UnderMaintenanceLogo from "../../../assets/undermaintances.png";

const MaintenanceContainer = styled.div`
  display: flex;
  height: 100vh;
  padding: 30px;
  align-items: center;
  > div {
    text-align: center;
    width: 50%;
    .img {
      width: 80%;
      height: auto;
    }
  }
  .msg {
    h1 {
      margin-bottom: 50px;
    }
  }
`;

export const UnderMaintenance = () => {
  return (
    <MaintenanceContainer>
      <div className="msg">
        <h1>{process.env.REACT_APP_MAINTENANCE_TITLE}</h1>
        <p>{process.env.REACT_APP_MAINTENANCE_MSG}</p>
      </div>

      <div>
        <img className="img" src={UnderMaintenanceLogo} />
      </div>
    </MaintenanceContainer>
  );
};
