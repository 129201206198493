import { utilityConstants } from "../_constants";

export function utilityBill(state = {}, action) {
  switch (action.type) {
    case utilityConstants.GET_ALL_BILLS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case utilityConstants.GET_ALL_BILLS_SUCCESS:
      return {
        ...state,
        utilityLists: action.data.ubList,
        ubListCount: action.data.ubListCount,
        loading: false,
      };
    case utilityConstants.GET_ALL_BILLS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case utilityConstants.GET_ALL_PROVIDER_REQUEST:
      return {
        ...state,
        utilityLists: [],
        ubListCount: 0,
      };
    case utilityConstants.GET_ALL_PROVIDER_SUCCESS:
      return {
        ...state,
        utilityProvider: action.data,
        utilityLists: [],
        ubListCount: 0
      };
    case utilityConstants.GET_ALL_PROVIDER_FAILURE:
      return {
        ...state,
        utilityProvider: [{ key: "", text: "Select Provider" }],
        error: action.error,
        utilityLists: [],
        ubListCount: 0,
        loading: false,
      };

      case utilityConstants.GET_MARKET_LIST_REQUEST:
        return {
          ...state,
          marketList: [],
        };
      case utilityConstants.GET_MARKET_LIST_SUCCESS:
        return {
          ...state,
          marketList: action.data,
        };
      case utilityConstants.GET_MARKET_LIST_FAILURE:
        return {
          ...state,
          marketList: [{ key: "", text: "Select Market" }],
          error: action.error,
        };

    case utilityConstants.GET_UTILITY_BILL_REQUEST:
      return Object.assign({}, state, { loading: true });

    case utilityConstants.GET_UTILITY_BILL_SUCCESS:
      return {
        ...state,
        utilityBillData: action.utilityBillData.utilityBill,
        ...(action.utilityBillData.utilityBillHistory && {
          utilityBillHistory: action.utilityBillData.utilityBillHistory,
        }),
        loading: false,
        error: "",
      };

    case utilityConstants.GET_UTILITY_BILL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case utilityConstants.PUT_UTILITY_BILL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case utilityConstants.PUT_UTILITY_BILL_SUCCESS:
      return {
        ...state,
        concessionList: action.utilityBillData,
        utilityBillData: action.utilityBillData,
        ...(action.utilityBillData.utilityBillHistory && {
          utilityBillHistory: action.utilityBillData.utilityBillHistory,
        }),
        loading: false,
      };
    case utilityConstants.PUT_UTILITY_BILL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case utilityConstants.GET_ADDRESS_SUGGESTION_REQUEST:
      return {
        ...state,
      };
    case utilityConstants.GET_ADDRESS_SUGGESTION_SUCCESS:
      return {
        ...state,
        addressSuggestionList: action.data,
      };
      case utilityConstants.POST_AI_PLAYGROUND_RESET:
      return {
        ...state,
        aiPlayGround: "",
        aiPlayGroundLoading: false,
      };
    case utilityConstants.POST_AI_PLAYGROUND:
      return {
        ...state,
        aiPlayGround: "",
        aiPlayGroundLoading: true,
      };
    case utilityConstants.POST_AI_PLAYGROUND_SUCCESS:
      return {
        ...state,
        aiPlayGround: (action.data || "").trim(),
        aiPlayGroundLoading: false,
      };
    case utilityConstants.POST_AI_PLAYGROUND_FAILURE:
      return {
        ...state,
        aiPlayGround: "",
        aiPlayGroundLoading: false,
      };

    case utilityConstants.GET_ALL_CCARD_SUCCESS:
      return {
        ...state,
        ccinfo: action.data,
      };
    case utilityConstants.GET_ALL_CCARD_FAILURE:
      return {
        ...state,
        ccinfo: [],
      };

    default:
      return state;
  }
}
