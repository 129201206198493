import { viewConstants } from '../_constants';

export function view(state = {}, action) {
  
  switch (action.type) {
    case viewConstants.GET_VIEW_REQUEST:
      return {
        loading: true,
      };
    case viewConstants.GET_VIEW_SUCCESS:
      return {
        view: action.view,
      };
    case viewConstants.GET_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}