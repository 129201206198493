import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@uifabric/icons';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from './components/util/theme';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import { runWithAdal } from 'react-adal';  
import { authContext } from './adal-config';
import { UserRoleProvider } from './components/util/userRole';
import { AppConstantProvider } from './components/util/AppConstantContext';

initializeIcons();

const DO_NOT_LOGIN = false;  
runWithAdal(authContext, () => { 
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppConstantProvider>
      <UserRoleProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </UserRoleProvider>
      </AppConstantProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
},DO_NOT_LOGIN);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();