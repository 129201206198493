export const utilityConstants = {
  GET_ALL_BILLS_REQUEST: "GET_ALL_BILLS_REQUEST",
  GET_ALL_BILLS_SUCCESS: "GET_ALL_BILLS_SUCCESS",
  GET_ALL_BILLS_FAILURE: "GET_ALL_BILLS_FAILURE",

  GET_UTILITY_BILL_REQUEST: "GET_UTILITY_BILL_REQUEST",
  GET_UTILITY_BILL_SUCCESS: "GET_UTILITY_BILL_SUCCESS",
  GET_UTILITY_BILL_FAILURE: "GET_UTILITY_BILL_FAILURE",

  PUT_PUSHT_TO_PW: "PUT_PUSHT_TO_PW",
  PUT_PUSHT_TO_PW_SUCCESS: "PUT_PUSHT_TO_PW_SUCCESS",
  PUT_PUSHT_TO_PW_FAILURE: "PUT_PUSHT_TO_PW_FAILURE",

  PUT_UTILITY_BILL_REQUEST: "PUT_UTILITY_BILL_REQUEST",
  PUT_UTILITY_BILL_SUCCESS: "PUT_UTILITY_BILL_SUCCESS",
  PUT_UTILITY_BILL_FAILURE: "PUT_UTILITY_BILL_FAILURE",

  GET_ALL_PROVIDER_REQUEST: "GET_ALL_PROVIDER_REQUEST",
  GET_ALL_PROVIDER_SUCCESS: "GET_ALL_PROVIDER_SUCCESS",
  GET_ALL_PROVIDER_FAILURE: "GET_ALL_PROVIDER_FAILURE",

  GET_MARKET_LIST_REQUEST: "GET_MARKET_LIST_REQUEST",
  GET_MARKET_LIST_SUCCESS: "GET_MARKET_LIST_SUCCESS",
  GET_MARKET_LIST_FAILURE: "GET_MARKET_LIST_FAILURE",

  GET_ADDRESS_SUGGESTION_REQUEST: "GET_ADDRESS_SUGGESTION_REQUEST",
  GET_ADDRESS_SUGGESTION_SUCCESS: "GET_ADDRESS_SUGGESTION_SUCCESS",
  GET_ADDRESS_SUGGESTION_FAILURE: "GET_ADDRESS_SUGGESTION_FAILURE",

  GET_ALL_CCARD_REQUEST: "GET_ALL_CCARD_REQUEST",
  GET_ALL_CCARD_SUCCESS: "GET_ALL_CCARD_SUCCESS",
  GET_ALL_CCARD_FAILURE: "GET_ALL_CCARD_FAILURE",

  POST_AI_PLAYGROUND: "POST_AI_PLAYGROUND",
  POST_AI_PLAYGROUND_SUCCESS: "POST_AI_PLAYGROUND_SUCCESS",
  POST_AI_PLAYGROUND_FAILURE: "POST_AI_PLAYGROUND_FAILURE",
  POST_AI_PLAYGROUND_RESET:  "POST_AI_PLAYGROUND_RESET",
};
