import { concessionFormConstants } from '../_constants';

export function concessionForm(state = {}, action) {
  
  switch (action.type) {
    case concessionFormConstants.POST_REQUEST:
      return {
        loading: true
      };
    case concessionFormConstants.POST_SUCCESS:
      return {
        concessionForm: action.concessionForm
      };
    case concessionFormConstants.POST_FAILURE:
      return {
        error: action.error
      };
    case concessionFormConstants.GETMARKET_REQUEST:
      return {
        loading: true
      };
    case concessionFormConstants.GETMARKET_SUCCESS:
      return {
        marketList: action.marketList
      };
    case concessionFormConstants.GETMARKET_FAILURE:
      return {
        error: action.error
      };

      case concessionFormConstants.GET_PORTFOLIO_REQUEST:
      return {
        loading: true
      };
    case concessionFormConstants.GET_PORTFOLIO_SUCCESS:
      return {
        portfolioList: action.portfolioList
      };
    case concessionFormConstants.GET_PORTFOLIO_FAILURE:
      return {
        error: action.error
      };

      case concessionFormConstants.GET_PROPERTY_REQUEST:
      return {
        loading: true
      };
    case concessionFormConstants.GET_PROPERTY_SUCCESS:
      return {
        propertyList: action.propertyList
      };
    case concessionFormConstants.GET_PROPERTY_FAILURE:
      return {
        error: action.error
      };

      case concessionFormConstants.GET_PROPERTYABBR_REQUEST:
      return {
        loading: true
      };
    case concessionFormConstants.GET_PROPERTYABBR_SUCCESS:
      return {
        propertyAbbreviations: action.propertyAbbreviations
      };
    case concessionFormConstants.GET_PROPERTYABBR_FAILURE:
      return {
        error: action.error
      };
      
    default:
      return state
  }
}