export const contractConstants = {
    CREATE_REQUEST: 'CONTRACT_CREATE_REQUEST',
    CREATE_SUCCESS: 'CONTRACT_CREATE_SUCCESS',
    CREATE_FAILURE: 'CONTRACT_CREATE_FAILURE',
    
    GET_REQUEST: 'CONTRACT_GET_REQUEST',
    GET_SUCCESS: 'CONTRACT_GET_SUCCESS',
    GET_FAILURE: 'CONTRACT_GET_FAILURE',
    
    EDIT_REQUEST: 'CONTRACT_EDIT_REQUEST',
    EDIT_SUCCESS: 'CONTRACT_EDIT_SUCCESS',
    EDIT_FAILURE: 'CONTRACT_EDIT_FAILURE',

    GETALL_REQUEST: 'CONTRACT_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONTRACT_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONTRACT_GETALL_FAILURE',
    
    GETALL_STATUS_REQUEST: 'GETALL_STATUS_REQUEST',
    GETALL_STATUS_SUCCESS: 'GETALL_STATUS_SUCCESS',
    GETALL_STATUS_FAILURE: 'GETALL_STATUS_FAILURE',   
    
    GETALL_OFFICE_REQUEST: 'GETALL_OFFICE_REQUEST',
    GETALL_OFFICE_SUCCESS: 'GETALL_OFFICE_SUCCESS',
    GETALL_OFFICE_FAILURE: 'GETALL_OFFICE_FAILURE',

    DELETE_ITEM_REQUEST: 'CONTRACT_ITEM_DELETE_REQUEST',
    DELETE_ITEM_SUCCESS: 'CONTRACT_ITEM_DELETE_SUCCESS',
    DELETE_ITEM_FAILURE: 'CONTRACT_ITEM_DELETE_FAILURE',

    DELETE_REQUEST: 'CONTRACT_DELETE_REQUEST',
    DELETE_SUCCESS: 'CONTRACT_DELETE_SUCCESS',
    DELETE_FAILURE: 'CONTRACT_DELETE_FAILURE'    
};
