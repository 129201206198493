export function sortObject(data) {
  return Object.keys(data).sort().reduce(
    (obj, key) => {
      obj[key] = data[key];
      return obj;
    },
    {}
  );
}

export function checkBoolean(value) {
  if (value === true || value === false) {
    return 'This is not a Bollean';
  }
};

export function checkNumber(value) {
  if (isNaN(value) || value - Math.floor(value) !== 0) {
    return 'This is not a Number';
  }
};

export function checkDecimal(value) {
  if (value - Math.floor(value) !== 0) {
    return 'This is not a Decimal';
  }
};

