import { contractConstants } from '../_constants';
import { vendorConstants } from '../_constants';

export function vendorsList(state = {}, action) {
  switch (action.type) {
    case vendorConstants.GET_ENTERIPRISE_REQUEST:
      return {
        loading: true
      };
    case vendorConstants.GET_ENTERIPRISE_SUCCESS:
      return {
        enterprise: action.enterprises
      };
    case vendorConstants.GET_ENTERIPRISE_FAILURE:
      return {
        error: action.error
      };

    case vendorConstants.GET_UTILITY_REQUEST:
      return {
        loading: true
      };
    case vendorConstants.GET_UTILITY_SUCCESS:
      return {
        utilityList: action.utilityList
      };
    case vendorConstants.GET_UTILITY_FAILURE:
      return {
        error: action.error
      };

    case vendorConstants.GET_UTILITY_DATA_REQUEST:
      return Object.assign({}, state, { loading: false });

    case vendorConstants.GET_UTILITY_DATA_SUCCESS:
      //return Object.assign({}, state, { loading: false, utilityData: action.utilityData });
      return {...state, utilityData : action.utilityData, error:'' };

    case vendorConstants.GET_UTILITY_DATA_FAILURE:      
      return {
         ...state,  error: action.error
      };

      case vendorConstants.GET_ZIPCODE_REQUEST:
      return {
        loading: true
      };
    case vendorConstants.GET_ZIPCODE_SUCCESS:
      console.log(state);
      return {...state, utilityList : [], zipCodeList : action.zipCodeList, error:'' };  
    
    case vendorConstants.GET_ZIPCODE_FAILURE:
      return {
        error: action.error
      };

    case vendorConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case vendorConstants.GETALL_SUCCESS:
      return {
        items: action.vendors
      };
    case vendorConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case contractConstants.DELETE_ITEM_REQUEST:
      return {
        loading: true
      };
    case contractConstants.DELETE_ITEM_SUCCESS:
      return {
        loading: false
      };
    case contractConstants.DELETE_ITEM_ERROR:
      return {
        error: action.error
      };
    case contractConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case contractConstants.DELETE_SUCCESS:
      // remove deleted user from state	  
      return {
        // items: state.items.filter(user => user.id !== action.id)
        items: state.items.filter(data => data.envelopeID !== action.id)
      };
    case contractConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    default:
      return state
  }
}