import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {  
 
  tenant: process.env.REACT_APP_AZUREAD_TENANT,
  
  clientId: process.env.REACT_APP_AZUREAD_CLIENTID,
   endpoints: {
   
      api:process.env.REACT_APP_AZUREAD_API
   }, 
  
  redirectUri: process.env.REACT_APP_AZUREAD_REDIRECTURI,
  postLogoutRedirectUri : process.env.REACT_APP_AZUREAD_POSTLOGOUTREDIRECTURI,
  cacheLocation: process.env.REACT_APP_AZUREAD_CACHELOCATION
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
    let auth_token = null;
    authContext.acquireToken(adalConfig.endpoints.api, (message, token, msg) => {
        if (token) {
            auth_token = token
        }
    });
   
    return (auth_token);

}
