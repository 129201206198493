import { rehabConstants } from '../_constants';

export function rehab(state = {}, action) {
  
  switch (action.type) {
    case rehabConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case rehabConstants.GETALL_SUCCESS:
      return {
        rehab: action.rehab,
      };
    case rehabConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}