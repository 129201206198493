export const concessionListConstants = {
    GETALL_REQUEST: 'CONCESSIONLIST_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONCESSIONLIST_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONCESSIONLIST_GETALL_FAILURE',  

    GETSTATUS_REQUEST: 'CONCESSIONLIST_GETSTATUS_REQUEST',
    GETSTATUS_SUCCESS: 'CONCESSIONLIST_GETSTATUS_SUCCESS',
    GETSTATUS_FAILURE: 'CONCESSIONLIST_GETSTATUS_FAILURE',

    GETMARKET_REQUEST: 'CONCESSIONLIST_GETMARKET_REQUEST',
    GETMARKET_SUCCESS: 'CONCESSIONLIST_GETMARKET_SUCCESS',
    GETMARKET_FAILURE: 'CONCESSIONLIST_GETMARKET_FAILURE',

    GETSEARCH_REQUEST: 'CONCESSIONLIST_GETSEARCH_REQUEST',
    GETSEARCH_SUCCESS: 'CONCESSIONLIST_GETSEARCH_SUCCESS',
    GETSEARCH_FAILURE: 'CONCESSIONLIST_GETSEARCH_FAILURE',

    GETID_REQUEST: 'CONCESSIONLIST_GETID_REQUEST',
    GETID_SUCCESS: 'CONCESSIONLIST_GETID_SUCCESS',
    GETID_FAILURE: 'CONCESSIONLIST_GETID_FAILURE',

    POST_REQUEST: 'CONCESSIONFORM_POST_REQUEST',
    POST_SUCCESS: 'CONCESSIONFORM_POST_SUCCESS',
    POST_FAILURE: 'CONCESSIONFORM_POST_FAILURE', 
};
