import { creditCardListConstants } from '../_constants';

export function creditCardList(state = {}, action) {  
  switch (action.type) {    
    case creditCardListConstants.GET_ALL_REQUEST:
      return {
        loading: true
      };
    case creditCardListConstants.GET_ALL_SUCCESS:
      return {
        creditCardLists: action.creditCardLists
      };
    case creditCardListConstants.GET_ALL_FAILURE:
      return {
        error: action.error
      };

    // case creditCardListConstants.GETSTATUS_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case creditCardListConstants.GETSTATUS_SUCCESS:
    //   return {
    //     statusList: action.statusList
    //   };
    // case creditCardListConstants.GETSTATUS_FAILURE:
    //   return {
    //     error: action.error
    //   };

    // case creditCardListConstants.GETMARKET_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case creditCardListConstants.GETMARKET_SUCCESS:
    //   return {
    //     marketList: action.marketList
    //   };
    // case creditCardListConstants.GETMARKET_FAILURE:
    //   return {
    //     error: action.error
    //   };

    case creditCardListConstants.GETSEARCH_REQUEST:
      return {
        loading: true
      };
    case creditCardListConstants.GETSEARCH_SUCCESS:
      return {
        creditCardLists: action.creditCardLists
      };
    case creditCardListConstants.GETSEARCH_FAILURE:
      return {
        error: action.error
      };

    // case creditCardListConstants.GETID_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case creditCardListConstants.GETID_SUCCESS:
    //   return {
    //     creditCardList: action.creditCardList
    //   };
    // case creditCardListConstants.GETID_FAILURE:
    //   return {
    //     error: action.error
    //   };

    // case creditCardListConstants.POST_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case creditCardListConstants.POST_SUCCESS:
    //   return {
    //     creditCardList: action.creditCardList
    //   };
    // case creditCardListConstants.POST_FAILURE:
    //   return {
    //     error: action.error
    //   };

    default:
      return state
  }
}