import { contractConstants } from '../_constants';

export function contract(state = {}, action) {
  switch (action.type) {
    case contractConstants.GET_REQUEST:
      return {
        loading: true
      };
    case contractConstants.GET_SUCCESS:
      return {
        contract: action.contract
      };
    case contractConstants.GET_FAILURE:
      return {
        loading:false,
        error: action.error
      };
    case contractConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case contractConstants.GETALL_SUCCESS:
      return {
        items: action.contracts
      };
    case contractConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case contractConstants.GETALL_STATUS_REQUEST:
      return {
        loading: true
      };
    case contractConstants.GETALL_STATUS_SUCCESS:
      return {
        status: action.status
      };
    case contractConstants.GETALL_STATUS_FAILURE:
      return {
        error: action.error
      };

    case contractConstants.GETALL_OFFICE_REQUEST:
      return {
        loading: true
      };
    case contractConstants.GETALL_OFFICE_SUCCESS:
      return {
        office: action.office
      };
    case contractConstants.GETALL_OFFICE_FAILURE:
      return {
        error: action.error
      };

    case contractConstants.DELETE_ITEM_REQUEST:
      return {
        loading: true
      };
    case contractConstants.DELETE_ITEM_SUCCESS:
      return {
        loading: false
      };
    case contractConstants.DELETE_ITEM_ERROR:
      return {
        error: action.error
      };
    case contractConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case contractConstants.DELETE_SUCCESS:
      // remove deleted user from state	  
      return {
        // items: state.items.filter(user => user.id !== action.id)
        items: state.items.filter(data => data.envelopeID !== action.id)
      };
    case contractConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    default:
      return state
  }
}