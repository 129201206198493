export const creditCardFormConstants = {
    
    POST_REQUEST: 'CREDITCARDFORM_POST_REQUEST',
    POST_SUCCESS: 'CREDITCARDFORM_POST_SUCCESS',
    POST_FAILURE: 'CREDITCARDFORM_POST_FAILURE',  

    VIEW_GET_REQUEST: 'CREDITCARDFORM_VIEW_GET_REQUEST',
    VIEW_GET_SUCCESS: 'CREDITCARDFORM_VIEW_GET_SUCCESS',
    VIEW_GET_FAILURE: 'CREDITCARDFORM_VIEW_GET_FAILURE',

    GET_STATUS_REQUEST: 'CREDITCARDFORM_GET_STATUS_REQUEST',
    GET_STATUS_SUCCESS: 'CREDITCARDFORM_GET_STATUS_SUCCESS',
    GET_STATUS_FAILURE: 'CREDITCARDFORM_GET_STATUS_FAILURE',

    GET_OFFICE_REQUEST: 'CREDITCARDFORM_GET_OFFICE_REQUEST',
    GET_OFFICE_SUCCESS: 'CREDITCARDFORM_GET_OFFICE_SUCCESS',
    GET_OFFICE_FAILURE: 'CREDITCARDFORM_GET_OFFICE_FAILURE'
};
