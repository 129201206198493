import React from 'react';
import { get } from 'lodash-es';
import paths from 'path';
import { hierarchize } from './util/hierarchical';

const keyName = 'key';
const pathName = 'path';
const uniqueKeyName = 'uniqueKey';

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey
    ? parentUniqueKey + '.' + node[keyName]
    : node[keyName];

  const parentPath = get(parent, pathName, '');
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

const routeConfig = hierarchize(
  {
    key: 'home',
    name: 'DocuSign Contract',
    icon: 'Home',
    path: '/',    
    rolesAssigned: 'Manager',
    component: React.lazy(() => import('./components/pages/Contracts/ContractList')),
    children: [
      {
        key: 'login',
        name: 'Login',
        isPublic: true,
        isHidden: true,
        component: React.lazy(() => import('./components/pages/Login')),
      },
      {
        key: 'profile',
        name: 'Profile',
        isHidden: true
      },
      {
        key: 'viewContract',
        name: 'View Contract',
        path: '/view-contract/:id',        
        rolesAssigned: 'Manager',
        isHidden: true,
        component: React.lazy(() => import('./components/pages/Contracts/ViewContract'))
      },
      {
        key: 'viewDetails',
        name: 'View Details',
        path: '/view-details/:id',        
        rolesAssigned: 'Manager',
        isHidden: true,
        component: React.lazy(() => import('./components/pages/RehabManagement/ViewDetails'))
      },
      {
        key: 'rehabManagement',
        name: 'Rehab Management',
        path: '/rehabmanagement',        
        rolesAssigned: 'Manager',
        icon: 'CompassNW',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/RehabManagement/RehabManagementList'))
      },
      {
        key: 'reviews',
        name: 'Reviews',
        path: '/reviews',        
        rolesAssigned: 'Manager',
        icon : 'VerifiedBrand',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/Reviews/Reviews'))
      },
      {
        key: 'unitmap',
        name: 'Unit Map',
        path: '/unitmap',        
        rolesAssigned: 'Manager',
        icon: 'MapLayers',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/UnitMap/UnitMap'))
      },
      {
        key: 'orgchart',
        name: 'Org Chart',
        path: '/orgchart',        
        rolesAssigned: 'Manager',
        icon: 'BarChartVertical',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/OrgChart/OrgChart'))
      },
      {
        key: 'calendar',
        name: 'Calendar',
        path: '/calendar',        
        rolesAssigned: 'Manager',
        icon : 'Calendar',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/Calendar/Calendar'))
      },
      {
        key: 'spmdir',
        name: 'SPM Directory',
        path: '/spmdirectory',        
        rolesAssigned: 'Manager',
        icon: 'BarChartVertical',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/SPMDirectory/SPMDir'))
      },
      {
        key: 'concessionTracking',
        name: 'Concession Tracking',
        path: '/concessionTracking',        
        rolesAssigned: 'Manager',
        icon: 'Trackers',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/ConcessionTracking/ContractList'))
      },
      {
        key: 'concessionView',
        name: 'Concession View',
        path: '/concession-view/:id',        
        rolesAssigned: 'Manager',
        isHidden: true,
        component: React.lazy(() => import('./components/pages/ConcessionTracking/ViewContract'))
      },
      {
        key: 'concessionForm',
        name: 'Concession Form',
        path: '/concessionForm',        
        rolesAssigned: 'Manager',
        icon: 'TextField',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/ConcessionForm/ViewContract'))
      },
      // {
      //   key: 'creditCardTracking',
      //   name: 'Creditcard Tracking',
      //   path: '/creditCardTracking',        
      //   rolesAssigned: 'Manager',
      //   isHidden: true,
      //   component: React.lazy(() => import('./components/pages/CreditCardTracking/CreditCardTracking'))
      // },
      // {
      //   key: 'creditCardForm',
      //   name: 'Credit Card Form',
      //   path: '/CreditCardForm',        
      //   rolesAssigned: 'Manager',
      //   isHidden: true,
      //   component: React.lazy(() => import('./components/pages/CreditCardForm/CreditCardForm'))
      // },
      // {
      //   key: 'creditCardView',
      //   name: 'Credit Card View',
      //   path: '/CreditCardView/:id',        
      //   rolesAssigned: 'Manager',
      //   isHidden: true,
      //   component: React.lazy(() => import('./components/pages/CreditCardTracking/CreditCardView'))
      // },
      {
        key: 'successPage',
        name: 'Success Page',
        path: '/success/:type',        
        rolesAssigned: 'Manager',
        isHidden: true,
        component: React.lazy(() => import('./components/pages/FormSuccess/FormSuccess'))
      },
      {
        key: 'vendors',
        name: 'Vendors',
        path: '/vendors',        
        rolesAssigned: 'Manager',
        icon:'AccountBrowser',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/Vendors/Vendors'))
      },
      {
        key: 'enterprise',
        name: 'Enterprise Search',
        path: '/enterprise',        
        rolesAssigned: 'Manager',
        icon: 'GiftboxOpen',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/Enterprise/Enterprise'))
      },
      {
        key: 'functionalOwner',
        name: 'Fractional Report',
        path: '/functionalOwner',        
        rolesAssigned: 'Manager',
        icon: 'FunctionalManagerDashboard',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/FunctionalReport/functionalReport'))
      },
      {
        key: 'utility-search',
        name: 'Utility Search',
        path: '/utility-search',        
        rolesAssigned: 'Manager',
        icon: 'SearchAndApps',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/UtilitySearch/UtilityList'))
      },
      {
        key: 'home',
        name: 'Utility Tools',
        path: '/utility-tools',
        rolesAssigned: 'Manager',
        icon: 'Home',
        isHidden : false,
        component: React.lazy(() => import('./components/pages/UtilityTools/UtilityToolsList')),
      },
      {
        key: 'viewUtilityTools',
        name: 'View Utility Tools',
        path: '/view-utility-tools/:id',        
        rolesAssigned: 'Manager',
        isHidden: true,
        component: React.lazy(() => import('./components/pages/UtilityTools/ViewUtilityTools'))
      },
      // {
      //   key: 'ai-playground',
      //   name: 'AI Playground',
      //   path: '/ai-playground',
      //   rolesAssigned: 'Manager',
      //   icon: 'System',
      //   isHidden : false,
      //   component: React.lazy(() => import('./components/pages/AIPlayground/AIPlayground')),
      // },
      {
        key: 'kpiReport',
        name: 'KPI Report',
        path: '/kpi-report',        
        rolesAssigned: 'Manager',
        isHidden: false,
        component: React.lazy(() => import('./components/pages/KpiReport/KpiReport'))
      },
    ]
  },
  null,
  pathGenerator
);

export default routeConfig;