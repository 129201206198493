import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import { getToken, authContext } from '../../adal-config';

const defaultValues = Object.freeze({
  userRole: '',
  setValue: () => { },
});
const AUTH_STOREGE_KEY = 'authentication';

export const UserRoleContext = React.createContext(defaultValues);

export function UserRoleProvider({ children }) {

  // const [userRole, setuserRole] = useState('');

  const [authentication, setAuthentication] = useSessionStorage(
    AUTH_STOREGE_KEY,
    defaultValues
  );


  const setValue = data => {    
    // setuserRole(data);
    setAuthentication({ userRole : data });

    }

  const values = { ...authentication, setValue};


  return (
    <UserRoleContext.Provider value={values}>
      {children}
    </UserRoleContext.Provider>
  );
}

export function useUserRole(props) {
  return React.useContext(UserRoleContext);
}
