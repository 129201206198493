import React from 'react';
import { CommandBarButton } from '@fluentui/react';
import { useAuthentication } from '../../util/authentication';
import { useHistory } from 'react-router-dom';
import {adalConfig, authContext} from '../../../adal-config';

export function UserMenu() {
  let tenant = adalConfig.tenant;
  let postLogoutRedirectUri = adalConfig.postLogoutRedirectUri;

  let user = authContext.getCachedUser();
  var username= (JSON.stringify(user.profile.name));
 
 
  const history = useHistory();
  const menuProps = {
    shouldFocusOnMount: true,
    items: [
      {
        key: 'profile',
        text: 'Profile',
        iconProps: { iconName: 'PlayerSettings' },
        onClick: () => history.push('/profile')
      },
      {
        key: 'logout',
        text: 'Logout',
        iconProps: { iconName: 'SignOut' },
        onClick: () => authContext.logOut()
		
	   
      }
    ]
  };

  return (
    <CommandBarButton
      menuProps={menuProps}
      iconProps={{ iconName: 'UserOptional' }}>
		  {username.replace(/[^a-zA-Z0-9 ]/g, "")}
    </CommandBarButton>
  );
}
