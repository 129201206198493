import React from 'react';
import { useSessionStorage } from 'react-use';
import {getToken, authContext} from '../../adal-config';

const defaultValues = Object.freeze({
  isAuthenticated: true,
  principal: null,
  login: () => {},
  logout: () => {}
});

const AUTH_STOREGE_KEY = 'authentication';

export const AuthenticationContext = React.createContext(defaultValues);

export function AuthenticationProvider({ children }) {
  const [authentication, setAuthentication] = useSessionStorage(
    AUTH_STOREGE_KEY,
    defaultValues
  );
 
  const login = principal =>
    setAuthentication({ isAuthenticated: true, principal });

  const logout = () =>  {
	  console.log("logout");
  window.location.replace("https://login.microsoftonline.com/common/oauth2/logout");
  }
  //setAuthentication(defaultValues);

  const values = { ...authentication, login, logout };

  return (
    <AuthenticationContext.Provider value={values}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  return React.useContext(AuthenticationContext);
}
