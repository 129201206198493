export const vendorConstants = {

    GETALL_REQUEST: 'CONTRACT_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONTRACT_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONTRACT_GETALL_FAILURE',

    GET_ENTERIPRISE_REQUEST: 'ENTERPRISE_GET_REQUEST',
    GET_ENTERIPRISE_SUCCESS: 'ENTERPRISE_GET_SUCCESS',
    GET_ENTERIPRISE_FAILURE: 'ENTERPRISE_GET_FAILURE',

    GET_UTILITY_REQUEST: 'UTILITY_GET_REQUEST',
    GET_UTILITY_SUCCESS: 'UTILITY_GET_SUCCESS',
    GET_UTILITY_FAILURE: 'UTILITY_GET_FAILURE',

    GET_UTILITY_DATA_REQUEST: 'UTILITY_DATA_REQUEST',
    GET_UTILITY_DATA_SUCCESS: 'UTILITY_DATA_GET_SUCCESS',
    GET_UTILITY_DATA_FAILURE: 'UTILITY_DATA_GET_FAILURE',

    GET_ZIPCODE_REQUEST: 'ZIPCODE_GET_REQUEST',
    GET_ZIPCODE_SUCCESS: 'ZIPCODE_GET_SUCCESS',
    GET_ZIPCODE_FAILURE: 'ZIPCODE_GET_FAILURE'

};
