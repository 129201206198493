import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import { getToken, authContext } from '../../adal-config';

const defaultValues = Object.freeze({
  marketList: null,
  statusList:null,
  portfolioList: null,
  propertyList: null,
  MarketListFn: () => { },
  StatusListFn: () => { },
  PortfolioListFn: () => { },
  PropertyListFn: () => { }
});



export const AppConstantContext = React.createContext(defaultValues);


export function AppConstantProvider({ children }) {
  const [authentication, setAuthentication] = useSessionStorage(
    defaultValues
  );

  const MarketListFn = data => {
     setAuthentication({...authentication, marketList : data} );  }
  const StatusListFn = data => {    
      setAuthentication({...authentication, statusList: data });    
  }
  const PortfolioListFn = data => {
   setAuthentication({...authentication, portfolioList: data });
  }
  const PropertyListFn = data => {   
    setAuthentication({...authentication, propertyList: data });     
  }

  const values = { ...authentication, MarketListFn, StatusListFn, PortfolioListFn, PropertyListFn};

  return (
    <AppConstantContext.Provider value={values}>
      {children}
    </AppConstantContext.Provider>
  );
}

export function useAppConstantContext(props) {
  return React.useContext(AppConstantContext);
}
