import { emailConstants } from '../_constants';

export function email(state = {}, action) {
  
  switch (action.type) {   
    case emailConstants.GET_EMAIL_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.GET_EMAIL_SUCCESS:
      return {
        email: action.email,
      };
    case emailConstants.GET_EMAIL_FAILURE:
      return {
        error: action.error
      };
    case emailConstants.SET_EMAIL_FALSE:
      return {
        email: false,
      };
    default:
      return state
  }
}