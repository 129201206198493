import { creditCardFormConstants } from '../_constants';

export function creditCardForm(state = {}, action) {
   
  switch (action.type) {

    case creditCardFormConstants.POST_REQUEST:
      return {
        loading: true
      };

    case creditCardFormConstants.POST_SUCCESS:          
      return {
        creditCardForm: action.creditCardForm
      };
      
    case creditCardFormConstants.POST_FAILURE:
      return {
        error: action.error
      };

    case creditCardFormConstants.VIEW_GET_REQUEST:
      return {
        loading: true
      };
    case creditCardFormConstants.VIEW_GET_SUCCESS:
      return {
        creditCardForm: action.creditCardForm
      };
    case creditCardFormConstants.VIEW_GET_FAILURE:
      return {
        error: action.error
      };

    case creditCardFormConstants.GET_STATUS_REQUEST:
      return {
        loading: true
      };
    case creditCardFormConstants.GET_STATUS_SUCCESS:
      
      return {
        status: action.status
      };
    case creditCardFormConstants.GET_STATUS_FAILURE:
      return {
        error: action.error
      };

      case creditCardFormConstants.GET_OFFICE_REQUEST:
      return {
        loading: true
      };
    case creditCardFormConstants.GET_OFFICE_SUCCESS:
      
      return {
        offices: action.offices
      };
    case creditCardFormConstants.GET_OFFICE_FAILURE:
      return {
        error: action.error
      };

    //   case concessionFormConstants.GET_PORTFOLIO_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case concessionFormConstants.GET_PORTFOLIO_SUCCESS:
    //   return {
    //     portfolioList: action.portfolioList
    //   };
    // case concessionFormConstants.GET_PORTFOLIO_FAILURE:
    //   return {
    //     error: action.error
    //   };

    //   case concessionFormConstants.GET_PROPERTY_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case concessionFormConstants.GET_PROPERTY_SUCCESS:
    //   return {
    //     propertyList: action.propertyList
    //   };
    // case concessionFormConstants.GET_PROPERTY_FAILURE:
    //   return {
    //     error: action.error
    //   };

    default:
      return state
  }
}