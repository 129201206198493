import { concessionListConstants } from '../_constants';

export function concessionList(state = {}, action) {
  
  switch (action.type) {
    case concessionListConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case concessionListConstants.GETALL_SUCCESS:
      return {
        concessionLists: action.concessionLists
      };
    case concessionListConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case concessionListConstants.GETSTATUS_REQUEST:
      return {
        loading: true
      };
    case concessionListConstants.GETSTATUS_SUCCESS:
      return {
        statusList: action.statusList
      };
    case concessionListConstants.GETSTATUS_FAILURE:
      return {
        error: action.error
      };

    case concessionListConstants.GETMARKET_REQUEST:
      return {
        loading: true
      };
    case concessionListConstants.GETMARKET_SUCCESS:
      return {
        marketList: action.marketList
      };
    case concessionListConstants.GETMARKET_FAILURE:
      return {
        error: action.error
      };

    case concessionListConstants.GETSEARCH_REQUEST:
      return {
        loading: true
      };
    case concessionListConstants.GETSEARCH_SUCCESS:
      return {
        concessionLists: action.concessionLists
      };
    case concessionListConstants.GETSEARCH_FAILURE:
      return {
        error: action.error
      };

    case concessionListConstants.GETID_REQUEST:
      return {
        loading: true
      };
    case concessionListConstants.GETID_SUCCESS:
      return {
        concessionList: action.concessionList
      };
    case concessionListConstants.GETID_FAILURE:
      return {
        error: action.error
      };

    case concessionListConstants.POST_REQUEST:
      return {
        loading: true
      };
    case concessionListConstants.POST_SUCCESS:
      return {
        concessionList: action.concessionList
      };
    case concessionListConstants.POST_FAILURE:
      return {
        error: action.error
      };

    default:
      return state
  }
}