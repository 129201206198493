export const concessionFormConstants = {
    POST_REQUEST: 'CONCESSIONFORM_POST_REQUEST',
    POST_SUCCESS: 'CONCESSIONFORM_POST_SUCCESS',
    POST_FAILURE: 'CONCESSIONFORM_POST_FAILURE',  

    GETMARKET_REQUEST: 'CONCESSIONFORM_GETMARKET_REQUEST',
    GETMARKET_SUCCESS: 'CONCESSIONFORM_GETMARKET_SUCCESS',
    GETMARKET_FAILURE: 'CONCESSIONFORM_GETMARKET_FAILURE',
    
    GET_PROPERTY_REQUEST: 'CONCESSIONFORM_GET_PROPERTY_REQUEST',
    GET_PROPERTY_SUCCESS: 'CONCESSIONFORM_GET_PROPERTY_SUCCESS',
    GET_PROPERTY_FAILURE: 'CONCESSIONFORM_GET_PROPERTY_FAILURE',

    GET_PORTFOLIO_REQUEST: 'CONCESSIONFORM_GET_PORTFOLIO_REQUEST',
    GET_PORTFOLIO_SUCCESS: 'CONCESSIONFORM_GET_PORTFOLIO_SUCCESS',
    GET_PORTFOLIO_FAILURE: 'CONCESSIONFORM_GET_PORTFOLIO_FAILURE',

    GET_PROPERTYABBR_REQUEST: 'CONCESSIONFORM_GET_PROPERTYABBR_REQUEST',
    GET_PROPERTYABBR_SUCCESS: 'CONCESSIONFORM_GET_PROPERTYABBR_SUCCESS',
    GET_PROPERTYABBR_FAILURE: 'CONCESSIONFORM_GET_PROPERTYABBR_FAILURE'
};
